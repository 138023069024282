import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react';
import { reactSelectStyles } from 'libs/react-select';
import { FC, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Select from 'react-select';

import MainLayout from 'components/layouts/MainLayout';
import { useMyStore } from 'features/my-store/hooks/useMyStore';
import { useStore } from 'features/my-store/hooks/useStore';
import { StoreType } from 'features/my-store/types/StoreType';
import CampaignsListItem from 'features/reservations/components/CampaignListItem';
import { CampaignListSkeletonBox } from 'features/reservations/components/SkeletonBoxes';
import { useCampaignList } from 'features/reservations/hooks/useCampaignList';

/** キャンペーン一覧 */
const CampaignsList: FC = () => {
  const { stores, isDone: isDoneStores } = useStore();
  const { myStores, isDone: isDoneMyStores } = useMyStore();

  const {
    campaigns,
    currentStoreId,
    setCurrentStoreId,
    isDone: isDoneCampaigns
  } = useCampaignList(null);

  const generateStoreOption = (store: StoreType) => ({
    value: store.id,
    label: store.name
  });
  const groupedStoreOptions = [
    {
      label: 'マイ店舗',
      options: myStores.map(generateStoreOption)
    },
    ...stores.map(({ prefecture, stores }) => ({
      label: prefecture,
      options: stores.map(generateStoreOption)
    }))
  ];

  const defaultStoreValue = useMemo(() => {
    let storeValue = null;
    if (myStores.length) {
      storeValue = generateStoreOption(myStores[0]);
    }
    setCurrentStoreId(storeValue?.value ?? null);
    return storeValue;
  }, [setCurrentStoreId, myStores]);

  return (
    <MainLayout bg="dark.50" header footer>
      <Container px="1rem" mt="3rem" mb="3.25rem">
        <FormControl mb="1.5em">
          <FormLabel>受取店舗選択</FormLabel>
          <Skeleton isLoaded={isDoneStores && isDoneMyStores}>
            <Select
              options={groupedStoreOptions}
              defaultValue={defaultStoreValue}
              onChange={(option) => {
                setCurrentStoreId(option?.value ?? null);
              }}
              onFocus={(e) => e.currentTarget.scrollIntoView()}
              styles={reactSelectStyles}
              placeholder="店舗を選択してください"
              noOptionsMessage={() => (
                <Text fontSize="md">検索されている店舗名はありません</Text>
              )}
              isClearable
              isMulti={false}
              isSearchable
            />
          </Skeleton>

          <Text fontWeight={400} pt="0.25rem">
            受取店舗を最初に選択してください。該当店舗で実施されているキャンペーンが表示されます。
          </Text>
        </FormControl>
        <VStack gap="0.5rem">
          {isDoneStores && isDoneMyStores && isDoneCampaigns ? (
            <>
              {campaigns.length > 0 ? (
                campaigns.map((campaign) => (
                  <CampaignsListItem
                    key={campaign.id}
                    campaign={campaign}
                    storeId={currentStoreId ?? -1}
                  />
                ))
              ) : (
                <Text fontWeight={400} fontSize="md" pt="0.25rem">
                  {currentStoreId
                    ? '開催中のキャンペーンはありません'
                    : '受取店舗が選択されていません'}
                </Text>
              )}
            </>
          ) : (
            <>
              <CampaignListSkeletonBox />
              <CampaignListSkeletonBox />
              <CampaignListSkeletonBox />
            </>
          )}
        </VStack>
      </Container>
      <Box
        w="100%"
        px="1rem"
        display="block"
        position="fixed"
        bottom="calc(6rem + var(--safe-area-inset-bottom))"
        mt="1rem"
      >
        <RouterLink to="/reservations/history">
          <Button variant="primary-fullwidth-rounded-shadow">
            注文履歴を確認する
          </Button>
        </RouterLink>
      </Box>
    </MainLayout>
  );
};

export default CampaignsList;

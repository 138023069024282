import { FluctBannerParams } from 'features/fluct/types';
import { appendScripts, createScriptElement } from 'libs/scriptLoader';
import React, { useEffect, useRef } from 'react';

/**
 * 以下のdivとスクリプトを発行する
 * <div class="fluct-unit-1000292826-1"></div>
 * <script type="text/javascript" src="https://pdn.adingo.jp/p.js" async></script>
 * <script type="text/javascript">
 *   var fluctAdScript = fluctAdScript || {};
 *   fluctAdScript.cmd = fluctAdScript.cmd || [];
 *   fluctAdScript.cmd.push(function (cmd) {
 *     cmd.setGamTargetingMap('%%PATTERN:TARGETINGMAP%%');
 *     cmd.requestAdByGroup("1000184515").display(".fluct-unit-1000292826-1", "1000292826");
 *   });
 * </script>
 */
export const Banner: React.FC<FluctBannerParams> = ({
  groupId,
  unitName,
  unitId
}) => {
  const adTagRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const adTag = adTagRef.current;
    const adDisplayConfigScript = createScriptElement(`
      var fluctAdScript = fluctAdScript || {};
      fluctAdScript.cmd = fluctAdScript.cmd || [];
      fluctAdScript.cmd.push(function (cmd) {
        cmd.setGamTargetingMap('%%PATTERN:TARGETINGMAP%%');
        cmd.requestAdByGroup("${groupId}")
          .display(".${unitName}", "${unitId}");
      });
    `);

    if (adTag) {
      appendScripts(adTag, [adDisplayConfigScript]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={unitName}></div>
      <div ref={adTagRef} role="presentation"></div>
    </>
  );
};

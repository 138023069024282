import { FluctSettingsApiResponse, PageType } from 'features/fluct/types';

/**
 * キャッシュの保持期間
 */
const CACHE_EXPIRATION = 5 * 60 * 1000;

/**
 * API結果のキャッシュ
 */
const apiCache = new Map<
  PageType,
  { data: FluctSettingsApiResponse['data']; expires: number }
>();

export const getApiCache = (
  pageType: PageType
): FluctSettingsApiResponse['data'] | null => {
  const cached = apiCache.get(pageType);
  if (cached && Date.now() <= cached.expires) {
    return cached.data;
  }
  deleteApiCache(pageType);
  return null;
};

export const setApiCache = (
  pageType: PageType,
  data: FluctSettingsApiResponse['data']
) => {
  apiCache.set(pageType, {
    data: data,
    expires: Date.now() + CACHE_EXPIRATION
  });
};

export const deleteApiCache = (pageType: PageType) => {
  apiCache.delete(pageType);
};

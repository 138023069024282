import { Flex } from '@chakra-ui/react';
import { Banner } from 'features/fluct/components/Banner';
import { Popup } from 'features/fluct/components/Popup';
import { useFluct } from 'features/fluct/hooks';
import { PageType } from 'features/fluct/types';
import React from 'react';

type FluctBannerProps = {
  pageType: PageType;
  /** Popupを表示するかどうか */
  allowPopup: boolean;
};

export const StackedBanner: React.FC<FluctBannerProps> = ({
  pageType,
  allowPopup
}) => {
  // 指定された pageType の Fluctの配信設定を取得
  const { loading, data, error } = useFluct(pageType);

  // 読込中とか、エラーが発生したりとかしたときは何もしない
  if (loading || error || !data) {
    return <></>;
  }

  // 広告をレンダリングしていく
  const popupParams = data['popup'];
  const banners = data['banners'];

  return (
    <>
      {banners.length > 0 && (
        <Flex marginTop="1rem" gap="2" direction="column" alignItems="center">
          {banners.map((banner, index) => (
            <Banner
              key={index}
              groupId={banner.groupId}
              unitName={banner.unitName}
              unitId={banner.unitId}
            />
          ))}
        </Flex>
      )}

      {allowPopup && (
        <Popup
          deliveryUrl={popupParams.deliveryUrl}
          playerUrl={popupParams.playerUrl}
        />
      )}
    </>
  );
};

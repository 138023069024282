import {
  FluctSettingsApiParams,
  FluctSettingsApiResponse,
  PageType
} from 'features/fluct/types';
import { TENANT_CODE } from 'libs/constants';
import { getPackageApiBaseUrl } from 'libs/env';
import { getApiCache, setApiCache } from './api-cache';
import { FluctSettingsApiClient } from './fluct-settings-api-client';

/**
 * Fluctの配信設定を取得する
 *
 * @param {FluctSettingsApiParams} params - APIリクエストに必要なパラメータ
 * @param {string} params.baseUrl - APIのベースURL
 * @param {string} params.tenantCode - テナントコード
 * @param {string} params.pageType - Fluct表示先(rb: Fluct::Setting#page_type)
 * @returns {Promise<FluctSettingsApiResponse['data']>} Fluctでの配信用データ
 * @throws {import('./errors').NetworkError} ネットワーク接続に失敗した場合
 * @throws {import('./errors').ResponseError} APIからエラーレスポンスを受け取った場合
 * @throws {import('./errors').JsonParseError} レスポンスのJSONパースに失敗した場合
 *
 * @example
 * ```typescript
 * const data = await invokeFetchFluctSetting({
 *   baseUrl: 'https://package.digiclueapp.com',
 *   tenantCode: 'tenant123',
 *   pageType: 'member_card_top'
 * });
 * ```
 */
export const invokeFetchFluctSetting = async (
  params: FluctSettingsApiParams
): Promise<FluctSettingsApiResponse['data']> => {
  const client = new FluctSettingsApiClient(params);
  return client.call();
};

/**
 * [外部インターフェース]
 * Fluctの配信設定を取得する
 */
export const fetchFluctSetting = async (pageType: PageType) => {
  const data = getApiCache(pageType);
  if (data) {
    return data;
  }

  const baseUrl = getPackageApiBaseUrl();
  const tenantCode = TENANT_CODE;
  const result = await invokeFetchFluctSetting({
    baseUrl,
    tenantCode,
    pageType
  });
  setApiCache(pageType, result);
  return result;
};

import {
  FluctSettingsApiParams,
  FluctSettingsApiResponse
} from 'features/fluct/types';
import { JsonParseError, NetworkError, ResponseError } from './errors';

const API_PATH = '/api/fluct_settings';

export class FluctSettingsApiClient {
  private readonly baseUrl: string;
  private readonly tenantCode: string;
  private readonly pageType: string;

  constructor(params: FluctSettingsApiParams) {
    this.baseUrl = params.baseUrl;
    this.tenantCode = params.tenantCode;
    this.pageType = params.pageType;
  }

  async call(): Promise<FluctSettingsApiResponse['data']> {
    const url = this.generateRequestUrl();
    const headers = this.generateRequestHeader();
    const response = await this.invokeApi(url, headers);
    if (!response.ok) {
      throw new ResponseError(response);
    }

    try {
      const json = (await response.json()) as FluctSettingsApiResponse;
      return json.data;
    } catch (error) {
      throw new JsonParseError(response);
    }
  }

  private generateRequestUrl(): URL {
    return new URL(`${API_PATH}/${this.pageType}`, this.baseUrl);
  }

  private generateRequestHeader(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      'DIGICLUE-TENANT-CODE': this.tenantCode
    };
  }

  private async invokeApi(
    url: URL,
    headers: Record<string, string>
  ): Promise<Response> {
    try {
      return await window.fetch(url.toString(), {
        method: 'GET',
        headers: headers
      });
    } catch (error) {
      throw new NetworkError(url.toString(), error);
    }
  }
}

import CuminCompletePage from 'features/membership/components/cumin/CuminCompletePage';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  CuminPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import { digiclueplusRedirectParamsAtom } from 'libs/recoil/atom';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * カード番号付番/完了
 */
const CuminCardIssueComplete: FC = () => {
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));

  // Recoilに初期値である空文字以外に設定されている場合は、デジクルプラスへリダイレクトする
  const digiclueplusRedirectParams = useRecoilValue(
    digiclueplusRedirectParamsAtom
  );
  if (digiclueplusRedirectParams.wall_path !== '') {
    return <Navigate to="/redirect/digiclueplus" />;
  }

  const title =
    templateOptions?.signupMemberCompleteTitleContentOption?.content ?? '';
  const explanation =
    templateOptions?.signupMemberCompleteExplanationContentOption?.content ??
    '';
  const imageUrl =
    templateOptions?.signupMemberCompleteImageOption?.image_url ?? '';

  return (
    <CuminCompletePage
      title={title}
      explanation={explanation}
      imageUrl={imageUrl}
      topPagePath={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.CUMIN}`}
    />
  );
};
export default CuminCardIssueComplete;

import { FluctPopupParams } from 'features/fluct/types';
import { appendScripts, createScriptElement } from 'libs/scriptLoader';
import React, { useEffect, useRef } from 'react';

/**
 * 以下のような要素を生成する
 * <div class="gsm-gcm-ad" style="margin: 0 auto; padding: 0; position: relative;">
 *   <script type="text/javascript" src="{APIから取得}" async></script>
 *   <script type="text/javascript" src="{APIから取得}" async></script>
 *  </div>
 */
export const Popup: React.FC<FluctPopupParams> = ({
  deliveryUrl,
  playerUrl
}) => {
  const adTagRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const adTag = adTagRef.current;
    if (!adTag || deliveryUrl === null || playerUrl === null) {
      return;
    }

    const deliveryScript = createScriptElement(deliveryUrl, true);
    const playerScript = createScriptElement(playerUrl, true);
    appendScripts(adTag, [deliveryScript, playerScript]);
  }, [deliveryUrl, playerUrl]);

  if (deliveryUrl === null || playerUrl === null) {
    return <></>;
  }

  return (
    <div
      ref={adTagRef}
      role="presentation"
      className="gsm-gcm-ad"
      style={{ margin: '0 auto', padding: 0, position: 'relative' }}
    ></div>
  );
};

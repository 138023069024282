import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Container, Stack, Text, VStack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import { StackedBanner as FluctBanner } from 'features/fluct';
import useDispatchCardNumberError from 'features/membership/hooks/useDispatchCardNumberError';
import { SimplePageTemplateOptionKey } from 'features/membership/libs/recoil/selector';
import {
  PageTemplateOption,
  TemplateAliasCode
} from 'features/membership/types';
import { openWindow } from 'libs/line';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

type TopPageProps = TemplateAliasCode & {
  isChangeCardNumberFeatureActive?: boolean;
  templateOptions?: Record<
    Extract<
      SimplePageTemplateOptionKey,
      'topNoticeContentOption' | 'topLinkOption'
    >,
    PageTemplateOption | undefined
  >;
  // ツルハで「カード番号」のラベルを変更したいとの要望があったため、一時対応としてラベルをカスタマイズできるようにしています
  cardNumberLabel?: string;
  cardNumberChangeLabel?: string;
};

/**
 * トップページ
 * Simple, Pepper, Mintのテンプレートで利用する共通コンポーネント
 */
const SimpleTopPage: FC<TopPageProps> = ({
  templateAliasCode,
  templateOptions,
  isChangeCardNumberFeatureActive = true,
  cardNumberLabel = 'カード番号',
  cardNumberChangeLabel = 'カード再連携'
}) => {
  const membershipMemberResponse = useRecoilValue(membershipMemberResponseAtom);
  const linkLabel = templateOptions?.topLinkOption?.link_label;
  const linkUrl = templateOptions?.topLinkOption?.link_url;

  useDispatchCardNumberError(
    membershipMemberResponse?.status === 'succeeded'
      ? membershipMemberResponse?.data?.card_number
      : undefined
  );

  return (
    <MainLayout header footer>
      <Container pt="3rem">
        <Stack spacing="2rem">
          {/* カード番号 */}
          <LabeledValue
            label={cardNumberLabel}
            value={
              membershipMemberResponse &&
              membershipMemberResponse.status === 'succeeded'
                ? membershipMemberResponse.data.card_number.replace(
                    /\d{4}(?=.)/g,
                    '$& '
                  )
                : ''
            }
          />
          {/* 「カード再連携」ボタン */}
          {isChangeCardNumberFeatureActive && (
            <Button
              as={RouterLink}
              to={`/membership/${templateAliasCode}/edit`}
              variant="ghost"
              rightIcon={
                <ChevronRightIcon boxSize="1.25rem" color="dark.100" />
              }
              height="auto"
              px="0.5rem"
              py="1rem"
              justifyContent="space-between"
              borderRadius={0}
              borderBottomWidth="1px"
              borderBottomColor="dark.100"
              iconSpacing={0}
            >
              {cardNumberChangeLabel}
            </Button>
          )}
          <VStack gap="0.75rem">
            {/* 説明 */}
            {templateOptions?.topNoticeContentOption?.content && (
              <Text fontWeight="400" fontSize="sm" whiteSpace="pre-line">
                {templateOptions.topNoticeContentOption.content}
              </Text>
            )}
            {/* リンク */}
            {linkLabel && linkUrl && (
              <Button
                type="button"
                variant="primary-link"
                size="xl"
                fontWeight="700"
                padding="0"
                onClick={linkUrl ? () => openWindow(linkUrl) : undefined}
              >
                {linkLabel}
              </Button>
            )}
          </VStack>
        </Stack>
      </Container>
      <FluctBanner pageType="member_card_top" allowPopup={true} />
    </MainLayout>
  );
};

export default SimpleTopPage;

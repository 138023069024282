import { fetchFluctSetting } from 'features/fluct/api';
import { FluctSettingsApiResponse, PageType } from 'features/fluct/types';
import { useEffect, useRef, useState } from 'react';

type UseFluctResult = {
  loading: boolean;
  data: FluctSettingsApiResponse['data'] | null;
  error: Error | null;
};

const DELAY = 500;

/**
 * Fluctの設定をAPIから取得するeffect
 */
export const useFluct = (pageType: PageType): UseFluctResult => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FluctSettingsApiResponse['data'] | null>(
    null
  );
  const [error, setError] = useState<Error | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchFluctSetting(pageType);
        setData(response);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(fetchData, DELAY);

    // クリーンアップ
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [pageType]);

  return { loading, data, error };
};
